@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;600;700&family=Overpass:wght@300;400;500;600;700;800&display=swap");
* {
  color: #00203c;
  font-family: "Overpass", sans-serif;
}
.para {
  font-size: 1rem;
}

/* --------------------------------------------Header-------------------------------------------- */
.top-nav {
  justify-content: center;
  align-items: center;
  margin: 15px auto;
}
.register {
  gap: 10px;
}
.navbar {
  background-color: #00aeef;
}
.navbar-lists {
  gap: 2rem;
  font-size: 1rem;
}
.nav-list {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
}
.butn {
  background-color: #00aeef;
  padding: 6px 25px 6px 25px;
  color: #fff;
  border-radius: 25px;
  border: none;
}

/* ------------------------------------------- Hero Section ----------------------------------------- */
.gyr {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gyr strong,
span {
  color: #005bab;
  font-weight: 700;
  font-family: "Josefin Sans", sans-serif;
}
.gyr {
  width: 23ch;
  border-right: 2px solid #005bab;
  overflow: hidden;
  white-space: nowrap;
  color: #005bab;
  font-size: 1.5rem;
  line-height: 1.3;
  animation: typing 3s steps(18), blink 0.4s step-end infinite alternate;
}
@keyframes typing {
  from {
    width: 0;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
}
.hero-video {
  flex-direction: column;
  font-size: 1.2rem;
}
.hero-video {
  flex-direction: column;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
}
.hero-sec {
  justify-content: center;
}
.hero-content h1 {
  font-family: "Josefin Sans", sans-serif;
  color: #00aeef;
}
video {
  width: 385px;
}
.hero-line-2 {
  text-align: center;
  align-items: center;
}
.hero-line-2-icon {
  padding: 5px 8px;
  border: 2px solid #005bab;
  border-radius: 100px;
  margin: 10px;
}
.hero-line-2-icon path {
  border: 2px solid #005bab;
  color: #005bab;
}
.hero-line-2-text {
  font-size: 1rem;
  font-weight: 500;
  color: #005bab;
}
.book {
  border-radius: 50px;
  background-color: #005bab;
  width: 300px;
  padding-left: 25px;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
}
.cohp {
  border-radius: 50px;
  border: 1px solid #00aeef;
  padding-left: 25px;
  width: 300px;
  color: #00aeef;
  justify-content: flex-start;
  align-items: center;
}
.cohp-btn {
  color: #00aeef;
  font-size: 1rem;
  vertical-align: center;
  margin-bottom: 0;
}
.book-btn {
  color: #ffffff;
}
.hero-sec {
  gap: 50px;
}
.span {
  color: #005bab;
  margin: 0 10px 0 10px;
}

.cart-icon path {
  fill: #fff;
}

/* ----------------------------------------- Section two ------------------------------------------ */
.secTwo {
  height: 450px;
}
.tst {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.secTwo {
  background-color: #00aeef;
  padding: 3rem 0 12rem 0;
}
.tst img {
  width: 120px;
  height: 120px;
  transition: 0.2s;
}
.tst img:hover {
  width: 135px;
  height: 135px;
}

.tst-icon {
  background-color: #fff;
  padding: 35px;
  border-radius: 100%;
  overflow: visible;
  margin-bottom: 15px;
}

.tst-title {
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
}

/* ------------------------------------ Section three ------------------------------------------ */
.secThree-img img {
  width: auto;
  max-width: 100%;
  object-fit: cover;
  /* border: 2px solid red; */
}
/* .secThree-img {
  flex-shrink: 6;
} */

.secThree {
  background-color: rgb(255, 255, 255);
  margin-top: -10rem;
  /* gap: 50px; */
  padding: 0;
  border-radius: 25px;
  /* justify-content: center;  */
  font-size: 1.2rem;
  box-shadow: rgba(1, 24, 58, 0.2) 0px 8px 24px;
  overflow: hidden;
}

.secThree-info {
  margin: auto 0;
  flex-direction: column;
  width: 40%;
  padding: 0 50px 0 50px;
}

.secThree-info hr {
  margin: auto 0;
  color: #005bab;
  /* flex-direction: column; */
}
.sti {
  padding: 25px 0 25px 0;
  gap: 15px;
}
.secThree-info p {
  margin: 0;
  font-weight: 500;
  color: #005bab;
}
.secThree-info img {
  width: 30px;
  color: red;
}

/* ---------------------------------------- TestCards ------------------------------------------- */
.cards {
  gap: 25px;
}
.test-cards {
  gap: 25px;
  justify-content: center;
}
.test-card {
  margin-top: 25px;
  background-color: #f2f2f2;
  padding: 30px;
  border-top: 1px solid #005bab;
  border-bottom: 1px solid #005bab;
  border-radius: 25px;
  width: 350px;
  text-align: left;
}
.test-card h3 {
  color: #333333;
  font-size: 1.1rem;
  font-weight: 600;
}
.test-card span {
  color: #005bab;

  font-size: 2rem;
  font-weight: 600;
}
.test-btn {
  background-color: #00aeef;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 12px 36px;
}
.test-btns {
  justify-content: flex-end;
}

/* ------------------------------------------- Section Four ------------------------------------- */
.cards {
  justify-content: center;
}
.secFour {
  margin: 5rem auto;
}
.secFour-info {
  flex-direction: column;
  margin-top: 25px;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.secFour-info p {
  margin-top: 15px;
  width: 1000px;
}

/* ----------------------------------------- Section Five ------------------------------------------ */

.secFive {
  justify-content: center;
  text-align: center;
}
.s5-searchbox {
  justify-content: center;
  gap: 0;
  margin-bottom: 25px;
}
.s5-search {
  background-color: #ffffff;

  padding: 8px 24px;
  font-size: 1.2rem;
  color: #005bab;
  border-radius: 25px 0 0 25px;
}
.s5-searchBtn {
  background-color: #005bab;
  padding: 8px 24px;
  font-size: 1.2rem;
  color: #fff;
  border: 2px solid #005bab;
  border-radius: 0 25px 25px 0;
}
.s5-bookAtest {
  margin-top: 50px 0 25px 0;
}

/* ----------------------------------------- Section Six ------------------------------------------ */

/* ----------------------------------------- Footer ------------------------------------------ */
.footer-head {
  background-color: #005bab;
}
.fh-content {
  /* justify-content: center; */
  align-items: center;
  padding: 0;
  gap: 20px;
}
.fh {
  padding: 0;
  background-color: #005bab;
}
.fh-nl {
  gap: 15px;
  align-items: center;
  justify-content: space-evenly;
}
.fh-nl-p {
  margin: 0;
  color: #fff;
}
.fh-content p {
  padding: 15px 0;
  font-weight: 300;
  /* font-size: 14px; */
  color: #fff;
  margin: 0;
}
.fh-nl img:nth-last-child() {
  border: 2px solid red;
  background-color: #fff;
}
.fh-content img {
  width: 25px;
}

.footer {
  background-color: #00aeef;
}
.footer-navbar-lists {
  gap: 15px;
}
.footer-container {
  padding: 50px 0 25px 0;
  justify-content: space-between;
}
